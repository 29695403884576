import React, { useState } from 'react';

import {
    grid,
    titleText,
    subtitleText,
    contentText,
    button,
    tabsContainer,
    navContainer,
    navButton,
    list,
    active,
} from './faq.module.scss';
import { ISection } from '../../models/section.model';
import { IFaqCategory } from '../../models/faq.model';
import { getLessImportantHeading } from '../../utils/get-less-important-heading';

import SingleFaq from '../molecules/single-faq';
import Section from '../hoc/section';

export interface IFaq extends ISection {
    items: {
        faqCategories: IFaqCategory[];
    };
}

interface IFaqProps {
    className?: string;
    section: IFaq;
    TitleTag?: React.ElementType;
}

const Faq: React.FC<IFaqProps> = ({ className = '', section, TitleTag = 'h2' }) => {
    const {
        sectionId,
        content: { texts },
        css,
        style,
        items: { faqCategories },
    } = section;
    const QuestionTag = getLessImportantHeading(TitleTag);
    const [activeTab, setActiveTab] = useState(0);

    return (
        <Section
            sectionId={sectionId}
            className={className}
            classes={{
                container: grid,
                title: titleText,
                subtitle: subtitleText,
                description: contentText,
                button: button,
            }}
            TitleTag={TitleTag}
            title={texts[0]}
            subtitle={texts[1]}
            description={texts[2]}
            css={css}
            style={style}
        >
            {faqCategories.length > 0 && (
                <div className={tabsContainer}>
                    {faqCategories.length > 1 && (
                        <div className={navContainer}>
                            {faqCategories.map((category, index) => (
                                <button
                                    className={`${navButton} ${activeTab === index ? active : ''}`}
                                    onClick={() => setActiveTab(index)}
                                    key={`faq-button-${category.categoryId}`}
                                >
                                    {category.name}
                                </button>
                            ))}
                        </div>
                    )}
                    <div>
                        {faqCategories.map((category, index) => (
                            <ul
                                className={`${list} ${activeTab === index ? active : ''}`}
                                key={`faq-list-${category.categoryId}`}
                            >
                                {category.faqs.map((faq) => {
                                    return (
                                        <li key={`faq-list-item-${faq.faqId}`}>
                                            <SingleFaq singleFaq={faq} QuestionTag={QuestionTag} />
                                        </li>
                                    );
                                })}
                            </ul>
                        ))}
                    </div>
                </div>
            )}
        </Section>
    );
};

export default Faq;
